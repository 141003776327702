import { TFunction } from 'next-i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'

const schemaLoginForm = (t: TFunction) => {
  return z.object({
    email: z
      .string()
      .refine((value) => value.trim() !== '', {
        message: `${t('form:required_email_field')}`
      })
      .refine((value) => regexEmailValidation.test(value), {
        message: `${t('form:invalid_email')}`
      })
  })
}

export default schemaLoginForm
