import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import HeadMetaTags from '~/components/HeadMetaTags'
import configuration from '~/configuration'
import LoginContainer from '~/features/login'
import { withAuthProps } from '~/lib/next/with-auth'

function LoginPage({
  providers
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <>
      <HeadMetaTags title={configuration.seo.login} />
      <LoginContainer providers={providers} />
    </>
  )
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  return await withAuthProps(ctx)
}

export default LoginPage
