import { signIn } from 'next-auth/react'
import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { setCookie } from 'nookies'
import { Controller } from 'react-hook-form'
import configuration from '~/configuration'
import { IFormAction } from '~/core/@types/global'
import { Button } from '~/core/ui/Button'
import { Container } from '~/core/ui/Container'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { TypographyH2 } from '~/core/ui/Heading'
import { Input } from '~/core/ui/Input'
import { SocialButton } from '~/core/ui/SocialButton'
import { TypographyText } from '~/core/ui/Text'
import { currentTimeZone } from '~/core/utilities/common'
import schemaLoginForm from '~/lib/features/login/schema/login-form'
import { ILoginForm } from '~/lib/features/login/types'

const LoginView: React.FC<{
  providers: object
  onFinish: (data: ILoginForm, formAction: IFormAction) => Promise<void>
  isLoading: boolean
}> = ({ providers, onFinish, isLoading }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="mt-6 flex h-5 items-center justify-center space-x-1 text-center tablet:absolute tablet:right-0 tablet:top-0 tablet:mr-8 tablet:mt-12 tablet:h-auto tablet:flex-col tablet:items-end tablet:justify-end tablet:space-x-0 tablet:text-right">
        <TypographyText className="text-sm text-gray-600 dark:text-gray-300">
          <Trans i18nKey={'auth:login_have_account'} />
        </TypographyText>
        <Link href={configuration.path.register}>
          <TypographyText className="text-sm font-medium text-primary-400 hover:underline dark:text-primary-400">
            <Trans i18nKey={'auth:login_have_account_button'} />
          </TypographyText>
        </Link>
      </div>

      <Container overrideClass="max-w-[375px] tablet:max-w-[392px] px-4 tablet:px-0 min-h-[calc(100vh_-_44px)] tablet:min-h-screen">
        <div className="flex min-h-[calc(100vh_-_44px)] flex-col tablet:min-h-screen">
          <div className="mb-5 mt-10 hidden h-5 tablet:block" />

          <div className="flex shrink-0 grow flex-col items-center justify-center">
            <div className="flex w-full flex-col items-center">
              <Image
                width={64}
                height={64}
                alt="logo"
                src="/img/logo/logo-primary.png"
                priority
              />

              <div className="mt-10 mb-5 text-center tablet:my-10">
                <TypographyH2 className="text-center text-2xl text-gray-900 dark:text-gray-300 tablet:text-3xl">
                  <Trans i18nKey={'auth:login_welcome'} />
                </TypographyH2>
                <div className="mt-3">
                  <TypographyText className="text-base text-gray-600 dark:text-gray-300">
                    <Trans i18nKey={'auth:login_welcome_description'} />
                  </TypographyText>
                </div>
              </div>

              <DynamicImportForm
                id="login-form"
                className="mb-6 w-full border-b border-gray-100 pb-6 tablet:mb-8 tablet:pb-8"
                schema={schemaLoginForm(t)}
                onSubmit={onFinish}
                defaultValue={{}}
                noUseSubmit>
                {({ formState, control }) => {
                  return (
                    <>
                      <div className="mb-5 tablet:mb-6">
                        <Controller
                          control={control}
                          name="email"
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <FormControlItem
                              destructive={
                                formState.errors && !!formState.errors.email
                              }
                              destructiveText={
                                formState.errors &&
                                (formState.errors.email?.message as string)
                              }
                              labelRequired
                              label={`${t('auth:login_form_label_email')}`}>
                              <Input
                                placeholder={`${t(
                                  'form:email_field_placeholder'
                                )}`}
                                onChange={onChange}
                                value={value}
                                destructive={
                                  formState.errors && !!formState.errors.email
                                }
                              />
                            </FormControlItem>
                          )}
                        />
                      </div>

                      <Button
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        className="w-full"
                        htmlType="submit"
                        label={`${t('auth:login_button_submit_with_email')}`}
                      />
                    </>
                  )
                }}
              </DynamicImportForm>

              {providers && Object.keys(providers).length ? (
                <div className="w-full space-y-2">
                  {Object.values(providers).map((provider) => (
                    <div key={provider.name} className="flex-1">
                      <SocialButton
                        className="w-full"
                        onClick={() => {
                          setCookie(null, 'signInTimeZone', currentTimeZone)
                          return signIn(
                            provider.id,
                            {},
                            { timeZone: currentTimeZone }
                          )
                        }}
                        size="lg"
                        type={provider.id}
                        label={`${t('auth:register_social_with')} ${
                          provider.name
                        }`}
                      />
                    </div>
                  ))}

                  <TypographyText className="!mt-3 h-4 text-center text-xs text-gray-600">
                    <Trans i18nKey="auth:register_terms_and_privacy">
                      <a
                        href={configuration.path.termsOfUse}
                        target="_blank"
                        className="font-medium"
                      />
                      <a
                        href={configuration.path.privacyPolicy}
                        target="_blank"
                        className="font-medium"
                      />
                    </Trans>
                  </TypographyText>
                </div>
              ) : null}
            </div>
          </div>

          <div className="mb-8 mt-5 flex h-5 w-full items-center justify-center space-x-8 tablet:mb-10">
            <div>
              <a
                href={`mailto:${configuration.mailto}`}
                className="hover:underline">
                <TypographyText className="text-center text-sm font-medium text-gray-600">
                  <Trans i18nKey="auth:login_trouble_contact" />
                </TypographyText>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default LoginView
