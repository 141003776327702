import { useRouter } from 'next/router'
import { useCallback } from 'react'
import LayoutHybrid from '~/components/Layout/LayoutHybrid'
import LoginView from '~/components/Login'
import configuration from '~/configuration'
import { IFormAction } from '~/core/@types/global'
import { useRecaptcha, verifyRecaptcha } from '~/core/hooks/use-verify-captcha'
import useMiddlewareRequest from '~/core/middleware/use-middleware-request'
import AppRouteLoading from '~/core/ui/AppRouteLoading'
import { catchErrorFromRequest } from '~/core/utilities/catch-api-error'
import { ILoginForm } from '~/lib/features/login/types'

const LoginContainer: React.FC<{
  providers: object
}> = ({ providers }) => {
  const router = useRouter()
  const { trigger, isMutating: isLoading } = useMiddlewareRequest({
    endpoint: configuration.api.login,
    method: 'POST'
  })
  useRecaptcha()

  const navigateToVerifyEmailPage = useCallback(
    (data: ILoginForm) => {
      void router.push(`${configuration.path.verifyEmail}?email=${data.email}`)
    },
    [router]
  )

  const loginCallback = useCallback(
    async (data: ILoginForm, formAction: IFormAction) => {
      if (isLoading) {
        return
      }

      verifyRecaptcha(async (isNotGoogleBOT: boolean) => {
        if (isNotGoogleBOT) {
          try {
            await trigger({ ...data, provider: 'web' })
            navigateToVerifyEmailPage(data)
          } catch (error) {
            catchErrorFromRequest({ error, formAction })
          }
        }
      })
    },
    [trigger, isLoading, navigateToVerifyEmailPage]
  )

  return (
    <LayoutHybrid>
      <AppRouteLoading>
        <LoginView
          isLoading={isLoading}
          providers={providers}
          onFinish={loginCallback}
        />
      </AppRouteLoading>
    </LayoutHybrid>
  )
}

export default LoginContainer
